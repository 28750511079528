import * as React from 'react';
import { FC } from 'react';

import { Alert, Snackbar } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { notifications$, removeNotification } from '../../stores';

export const NotificationOutlet: FC = () => {
  const { t } = useTranslation([
    'address-check-form',
    'address-check-search',
    'address-check-public',
    'address-check-admin',
  ]);
  const [notifications] = useObservable(notifications$);
  const handleClose = (id: string) => removeNotification(id);
  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={3000}
          onClose={() => {
            handleClose(notification.id);
          }}
          ClickAwayListenerProps={{ onClickAway: () => null }}
          message={t(
            notification.notificationTranslation?.key ?? '',
            notification.notificationTranslation?.properties ?? {}
          )}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            variant={'filled'}
            onClose={() => {
              handleClose(notification.id);
            }}
            severity={notification.notificationType}
            sx={{
              width: '100%',
              '& .MuiAlert-message': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
            }}
          >
            {t(
              notification.notificationTranslation?.key ?? '',
              notification.notificationTranslation?.properties ?? {}
            )}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
