import React, {
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  completeAddressCheck,
  configuration$,
  configurationLoading$,
  currentStep$,
  editMode$,
  feedback$,
  teacherStaffLoading$,
  updateLotteryData,
  updateStep,
} from '../../store';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import {
  Box,
  Button,
  MobileStepper,
  Paper,
  SxProps,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ConfirmDialog, ScrollablePage } from '@verona/components';
import {
  AddressCheckStepProps,
  CompletionPage,
  LotteryPage,
  SchoolDataPage,
  TeacherInvitationPage,
  TeacherListPage,
  WelcomePage,
} from '../steps';
import { dispatch } from '@ngneat/effects';
import { AddressCheckInstitutionStepDto } from '@verona/address-check-public-api';

interface AddressCheckStep {
  name: AddressCheckInstitutionStepDto;
  step: ReactElement;
  crucialDataLoading?: boolean[];
}

export interface AddressCheckStepperProps {
  sx?: SxProps<Theme>;
}

export const AddressCheckStepper: FC<AddressCheckStepperProps> = ({ sx }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'stepper',
  });
  const theme = useTheme();
  const [params] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);

  const [currentStep] = useObservable(currentStep$);
  const [config] = useObservable(configuration$);

  const [configLoading] = useObservable(configurationLoading$);

  const [feedback] = useObservable(feedback$);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [editMode] = useObservable(editMode$);

  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);

  const [scrolledToBottom, setScrolledToBottom] = useState(true);

  const stepProps: AddressCheckStepProps = {
    addressCheckInstitutionId: params.get('code') ?? '',
    config,
    configLoading,
    isMobile,
  };

  const scrollPage = useRef<HTMLDivElement>();

  const [teacherStaffLoading] = useObservable(teacherStaffLoading$);

  const steps: AddressCheckStep[] = [
    { name: 'WELCOME', step: <WelcomePage {...stepProps} /> },
    { name: 'SCHOOL_DATA', step: <SchoolDataPage {...stepProps} /> },
    {
      name: 'TEACHER_LIST',
      crucialDataLoading: [teacherStaffLoading],
      step: <TeacherListPage {...stepProps} />,
    },
    {
      name: 'TEACHER_INVITATION',
      step: <TeacherInvitationPage {...stepProps} />,
    },
    ...(config?.lotteryDisabled ?? false
      ? []
      : [
          {
            name: 'LOTTERY',
            step: <LotteryPage {...stepProps} />,
          } satisfies AddressCheckStep,
        ]),
    { name: 'COMPLETION', step: <CompletionPage {...stepProps} /> },
  ].filter((it) => !!it) as AddressCheckStep[];

  useEffect(() => {
    const currentStepIndex = getStepIndex(currentStep);
    currentStepIndex !== -1 && setActiveStep(currentStepIndex);

    scrollToTop(scrollPage?.current);
  }, [currentStep]);

  const maxSteps = steps.length;

  const moveToStep = (index: number) => {
    if (editMode) {
      if (steps[activeStep]?.name === 'LOTTERY') {
        dispatch(updateLotteryData({ shouldDisplayError: true }));
      }
      setShowUnsavedChangesDialog(true);
    } else {
      setActiveStep(index);
      scrollToTop(scrollPage?.current);
      dispatch(
        updateStep({
          addressCheckInstitutionId: params.get('code') ?? '',
          body: steps[index]?.name,
        })
      );
    }
  };

  useEffect(() => {
    setScrolledToBottom(stepCompleted(activeStep));
    if (!isScrollable(scrollPage?.current) && crucialDataLoaded()) {
      onReachedBottom();
    }
  }, [scrollPage, activeStep]);

  const continueButtonDisabled = () =>
    !crucialDataLoaded ||
    (isScrollable(scrollPage?.current) && !scrolledToBottom);

  const crucialDataLoaded = () => {
    if (!steps[activeStep]?.crucialDataLoading) {
      return true;
    } else {
      return !steps[activeStep]?.crucialDataLoading?.some((x) => x);
    }
  };

  const scrollToTop = (box?: HTMLElement) =>
    box?.scroll({
      top: 0,
    });

  const onReachedBottom = () => {
    setScrolledToBottom(true);
  };

  const isScrollable = (box?: HTMLElement) =>
    box && box?.scrollHeight > box?.offsetHeight;

  const stepCompleted = (step: number) => step < getStepIndex(currentStep);

  const getStepIndex = (step: string) =>
    steps.findIndex(({ name }) => step === name);

  const feedbackHasContent = () => feedback.feedback?.replace(/\s/g, '').length;

  const getNextButtonLabel = () =>
    activeStep !== maxSteps - 1
      ? t('next.label')
      : feedbackHasContent() && !isMobile
      ? t('completeWithFeedback.label')
      : t('complete.label');

  const sendFeedbackAndLogout = () => {
    dispatch(
      updateStep({
        addressCheckInstitutionId: params.get('code') ?? '',
        body: 'DONE',
      })
    );

    dispatch(
      completeAddressCheck({
        addressCheckInstitutionId: params.get('code') ?? '',
        addressCheckFeedbackDto: feedback,
      })
    );
  };

  const getNextButtonAction = () =>
    activeStep !== maxSteps - 1
      ? moveToStep(activeStep + 1)
      : sendFeedbackAndLogout();

  const getConfirmDialogPrefix = () => {
    if ('LOTTERY' === steps[activeStep]?.name) return 'edit.lotteryDialog';
    return 'edit.dialog';
  };

  return (
    <>
      <ScrollablePage
        sx={{
          maxHeight: `calc(100dvh - ${isMobile ? 80 + 60 : 120 + 96}px)`,
          position: 'relative',
          pt: 0,
          display: 'flex',
          justifyContent: 'center',
          ...sx,
        }}
        reachedBottom={onReachedBottom}
        ref={scrollPage}
      >
        <Box sx={{ maxWidth: 1268, width: '100%' }}>
          {isMobile ? (
            <>
              {steps.map(
                ({ step }, i) =>
                  activeStep === i && (
                    <Box
                      key={step.key}
                      sx={{ p: 2, height: 'max-content' }}
                      hidden={activeStep !== i}
                    >
                      {cloneElement(step, { isActive: activeStep === i })}
                    </Box>
                  )
              )}
            </>
          ) : (
            <>
              {steps.map(
                ({ step }, i) =>
                  activeStep === i && (
                    <Box
                      key={step.key}
                      sx={{ p: 8, pb: 2 }}
                      hidden={activeStep !== i}
                    >
                      {cloneElement(step, { isActive: activeStep === i })}
                    </Box>
                  )
              )}
            </>
          )}
        </Box>
      </ScrollablePage>

      <Paper
        {...(!isMobile && { elevation: 4 })}
        sx={{
          left: 0,
          bottom: 0,
          width: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <MobileStepper
          {...(isMobile && { elevation: 4 })}
          sx={{
            position: 'relative',
            [theme.breakpoints.up('md')]: {
              maxWidth: 1280,
              width: '100%',
              bottom: 0,
              background: '#fff',
              height: 60,
              p: 8,
              py: 6,
            },
            [theme.breakpoints.down('md')]: {
              bottom: 0,
              position: 'fixed',
              background: '#fff',
              height: 60,
            },
            '.MuiMobileStepper-dot': {
              width: (theme) => theme.spacing(1.5),
              height: (theme) => theme.spacing(1.5),
            },
          }}
          position={'bottom'}
          activeStep={activeStep}
          backButton={
            <Button
              variant={'contained'}
              disabled={activeStep === 0}
              onClick={() => moveToStep(activeStep - 1)}
              startIcon={<KeyboardArrowLeft />}
            >
              {t('back.label')}
            </Button>
          }
          nextButton={
            <Tooltip
              title={continueButtonDisabled() ? t('next.tooltip') : ''}
              placement={'top'}
              open={continueButtonDisabled() ?? false}
              arrow
            >
              <Button
                variant={'contained'}
                disabled={continueButtonDisabled()}
                onClick={() => getNextButtonAction()}
                endIcon={<KeyboardArrowRight />}
              >
                {getNextButtonLabel()}
              </Button>
            </Tooltip>
          }
          steps={maxSteps}
        />
      </Paper>
      <ConfirmDialog
        title={t(`${getConfirmDialogPrefix()}.label`)}
        confirm={t(`${getConfirmDialogPrefix()}.confirm`)}
        open={showUnsavedChangesDialog}
        handleClose={() => {
          setShowUnsavedChangesDialog(false);
        }}
        content={<>{t(`${getConfirmDialogPrefix()}.content`)}</>}
      />
    </>
  );
};
