import * as React from 'react';
import { FC } from 'react';
import veronaLogo from '../../assets/Verona_Logo-Icon.svg';
import veronaTitle from '../../assets/Verona_Logo-Title.svg';
import { App, AppsMenu } from './apps-menu';
import { Box, Typography, useTheme } from '@mui/material';
import { MenuLink } from './menu-link';
import { APP_VERSION, HOSTING_ENVIRONMENT } from '@verona/utils';

export type AppLink = App;

export interface HeaderLink {
  key: string;
  title: string;
  link: string;
}

export const AdminLayoutHeader: FC<{
  title: string;
  apps: AppLink[];
  links: HeaderLink[];
}> = ({ title, apps, links }) => {
  const theme = useTheme();

  let headerColor: string;

  switch (HOSTING_ENVIRONMENT) {
    case 'development':
      headerColor = '#2390DE';
      break;
    case 'staging':
      headerColor = theme.palette.primary.main;
      break;
    default:
      headerColor = '#FFF';
      break;
  }

  return (
    <Box
      sx={{
        color: (theme) => theme.palette.grey[800],
        flex: '1 1',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1,
        py: 0,
        minHeight: 56,
        boxSizing: 'border-box',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        boxShadow: '0 2px 2px rgba(0,0,0,.1)',
        background: headerColor,
      }}
    >
      <Box sx={{ display: 'flex' }} alignItems={'center'}>
        <AppsMenu apps={apps} />
        <Box
          component={'img'}
          src={veronaLogo}
          alt="Verona"
          sx={{
            height: 28,
            marginRight: 1,
          }}
        />
        <Box
          component={'img'}
          src={veronaTitle}
          alt="Verona"
          sx={{
            height: 20,
            marginRight: 1,
          }}
        />
        <Typography
          variant={'h2'}
          sx={{
            fontSize: '1em',
            marginRight: 2,
            color: (theme) => theme.palette.grey[800],
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            padding: 0.5,
            paddingRight: 3,
            marginTop: 1,
            marginBottom: 0.5,
            // lineHeight: 1.3,
            textTransform: 'uppercase',
            fontWeight: 300,
          }}
        >
          {title}
        </Typography>

        {links?.map((link) => (
          <MenuLink key={link.key} to={link.link}>
            {link.title}
          </MenuLink>
        ))}

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            mr: 1,
            mt: 0.25,
          }}
        >
          <Typography variant={'caption'}>v-{APP_VERSION}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
