import * as ReactDOM from 'react-dom/client';

import { App } from './app/app';
import { BaseTheme } from '@verona/components';
import { MsalAuthProvider } from '@verona/admin-authentication';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BaseTheme>
    <MsalAuthProvider protectedRoutes={['/admin']}>
      <App />
    </MsalAuthProvider>
  </BaseTheme>
);
