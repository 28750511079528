import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import { initI18n } from './translation/config';
import { CssBaseline } from '@mui/material';
import { initEffects } from '@ngneat/effects';
import { enableElfProdMode } from '@ngneat/elf';
import {
  AdminLayoutHeader,
  AppLink as AdminHeaderAppLink,
  BaseThemePublic,
  HeaderLink as AdminHeaderMenuLink,
  LoadingIndicator,
} from '@verona/components';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PublicAuthGuard } from '@verona/address-check-public';
import { useEffects } from '@ngneat/effects-hooks';
import { isAuthenticated$, setAuth$ } from '@verona/admin-authentication';
import { useObservable } from '@ngneat/react-rxjs';
import { NotificationOutlet } from '@verona/notifications';
import { HOSTING_ENVIRONMENT } from '@verona/utils';

initI18n();
initEffects();
enableElfProdMode();

const AdminLayout: FC = () => {
  const [isAuthenticated] = useObservable(isAuthenticated$);
  const { t } = useTranslation('address-check');

  let veronaBaseUrl: string;
  let pumaBaseUrl: string;
  let orderManagementBaseUrl: string;

  useEffects([setAuth$]);
  useEffect(() => {
    document.title = t('header');
  }, [t]);

  switch (HOSTING_ENVIRONMENT) {
    case 'staging':
    case 'develop':
      pumaBaseUrl = 'https://puma-stage.veritas.at';
      orderManagementBaseUrl = 'https://order-stage.veritas.at';
      veronaBaseUrl = 'https://verona-stage.veritas.at';
      break;
    default:
      pumaBaseUrl = 'https://puma.veritas.at';
      orderManagementBaseUrl = 'https://order.veritas.at';
      veronaBaseUrl = 'https://verona.veritas.at';
      break;
  }

  const appLinks: AdminHeaderAppLink[] = [
    {
      title: t(`navigation.appsMenu.businessPartners`),
      url: `${pumaBaseUrl}/puma/people`,
    },
    {
      title: t(`navigation.appsMenu.products`),
      url: `${pumaBaseUrl}/puma/licenseCodesHeads`,
    },
    {
      title: t(`navigation.appsMenu.orderManagement`),
      url: `${orderManagementBaseUrl}`,
    },
    {
      title: t(`navigation.appsMenu.baseData`),
      url: `${veronaBaseUrl}/masterdata/subject`,
    },
    {
      title: t(`navigation.appsMenu.submissions`),
      url: `${veronaBaseUrl}/submissions`,
    },
    {
      title: t(`navigation.appsMenu.settings`),
      url: `${veronaBaseUrl}/settings/users`,
    },
  ];
  const menuLinks: AdminHeaderMenuLink[] = [
    {
      key: 'form',
      link: '/admin/form',
      title: t('navigation.items.create'),
    },
    {
      key: 'search',
      link: '/admin/search',
      title: t('navigation.items.overview'),
    },
    {
      key: 'settings',
      link: '/admin/settings',
      title: t('navigation.items.settings'),
    },
  ];

  return !isAuthenticated ? (
    <LoadingIndicator
      loading={true}
      type={'linear'}
      infoText={t('loading.login') as string}
    />
  ) : (
    <>
      <CssBaseline />
      <AdminLayoutHeader
        title={t('header')}
        apps={appLinks}
        links={menuLinks}
      />
      <Outlet />
    </>
  );
};

const PublicLayout: FC = () => {
  return (
    <BaseThemePublic>
      <CssBaseline />
      <Outlet />
    </BaseThemePublic>
  );
};

export const App: FC = () => {
  const { t } = useTranslation('address-check');

  const router = createBrowserRouter([
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        {
          index: true,
          element: <Navigate replace to="/admin/search" />,
        },
        {
          path: '/admin/form',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (await import('@verona/address-check-admin-form'))
                  .AddressCheckFormPage,
              }),
            },
          ],
        },
        {
          path: '/admin/search',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (await import('@verona/address-check-admin-search'))
                  .AddressCheckSearchPage,
              }),
            },
          ],
        },
        {
          path: '/admin/settings',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (
                  await import('@verona/address-check-admin-settings')
                ).AddressCheckSettingsPage,
              }),
            },
          ],
        },
        {
          path: '/admin/quick-create',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (
                  await import('@verona/address-check-admin-quick-create')
                ).AddressCheckQuickCreatePage,
              }),
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: <PublicLayout />,
      children: [
        {
          index: true,
          lazy: async () => ({
            Component: (await import('@verona/address-check-public'))
              .AddressCheckPublicLoginPage,
          }),
        },
      ],
    },
    {
      path: '*',
      element: (
        <PublicAuthGuard>
          <PublicLayout />
        </PublicAuthGuard>
      ),
      children: [
        {
          index: true,
          lazy: async () => ({
            Component: (await import('@verona/address-check-public'))
              .AddressCheckPublicPage,
          }),
        },
      ],
    },
  ]);

  return (
    <>
      <NotificationOutlet />
      <RouterProvider
        router={router}
        fallbackElement={
          <LoadingIndicator
            loading
            type={'linear'}
            infoText={t('loading.addressCheck') as string}
          />
        }
      />
    </>
  );
};
