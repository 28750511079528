import * as React from 'react';
import { ComponentProps, FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography, useTheme } from '@mui/material';

export const MenuLink: FC<
  ComponentProps<typeof NavLink> & { children: ReactNode }
> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <NavLink
      to={props.to}
      className={({ isActive, isPending }) =>
        classes.menuLink + (isActive ? ' ' + classes.currentMenuLink : '')
      }
    >
      <Typography component={'h3'} color={theme.palette.text.primary}>
        {props.children}
      </Typography>
    </NavLink>
  );
};

const useStyles = makeStyles({
  menuLink: {
    padding: 8,
    textDecoration: 'none',
    borderRadius: 4,
    position: 'relative',

    '&:hover': {
      background: 'rgba(0, 0, 0, .05)',
    },

    '& > p': {
      fontWeight: 500,
      fontSize: '1rem',
    },
  },
  currentMenuLink: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      borderBottom: `3px solid #000`,
      opacity: '1 !important',
      left: 8,
      top: 45,
      width: `calc(100% - ${2 * 8}px)`,
    },
  },
});
