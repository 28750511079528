import i18n from 'i18next';
import localeDE from '../../assets/locales.de.json';
import { initReactI18next } from 'react-i18next';

export function initI18n() {
  i18n
    .use(initReactI18next)
    .init({
      resources: { de: localeDE },
      lng: 'de',
      fallbackLng: 'de',
      interpolation: {
        escapeValue: false,
        // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    })
    .then((response) => console.log(response));
}
