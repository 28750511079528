import * as React from 'react';
import { FC } from 'react';
import veronaLogo from '../../assets/Verona_Logo-Icon.svg';
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { Apps } from '@mui/icons-material';

export interface App {
  title: string;
  url: string;
}

interface AppsMenuProps {
  apps: App[];
}

export const AppsMenu: FC<AppsMenuProps> = ({ apps }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function renderApp(app: App, idx: number) {
    return (
      <ListItemLink key={idx} href={app.url}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Box
            component={'img'}
            src={veronaLogo}
            alt="Verona"
            sx={{
              height: 32,
              borderRadius: 1,
              padding: 0.5,
              backgroundColor: '#FFCC66',
            }}
          />
        </ListItemIcon>
        <ListItemText primary={app.title} />
      </ListItemLink>
    );
  }

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Apps sx={{ m: 0.5 }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            width: 350,
            padding: 2,
            paddingBottom: 1,
          }}
        >
          <Typography
            variant={'h2'}
            sx={{
              marginTop: 1,
              marginLeft: 2,
            }}
          >
            {'Wechseln zu'}
          </Typography>
          <List component="nav">{apps.map(renderApp)}</List>
        </Box>
      </Popover>
    </>
  );
};

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItemButton component="a" {...props} />;
}
